<div class="w-100 mb-20px">
  <div class="ai-input">
    <span class="icon">
      <img [src]="icon.roamAi.src" [alt]="icon.roamAi.alt" />
    </span>

    <input
      autofocus
      type="text"
      class="ai-input-control"
      [(ngModel)]="command"
      [disabled]="isProcessText"
      (keyup.enter)="execute()"
    />

    <span
      class="icon c-pointer"
      [class.move-up-down]="isProcessText"
      (click)="execute()"
    >
      <span
        class="btn"
        [class.item-icon]="isProcessText"
        [inlineSVG]="icon.btnUpChange.src"
      >
      </span>
    </span>
  </div>
</div>
